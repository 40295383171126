.cardShadow {
    box-shadow: 0 3px 15px 0 rgba(50,50,50,.15);
    border-radius: 0!important;
    border: none;
}

.navButton {
    background-color: #025BB3!important;
}
.navButton:after {
    content: none!important;
}

.faqToggle {
    background-color: #F6F9FC!important;
    border: unset;
}

.dropdownItem:hover {
    background-color: #025BB3!important;
    color: white;
}
.dropdownCurrent {
    background-color: #025BB3!important;
    color: white;
}

.singleOptionGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.singleOption.btn-outline-primary:not(:hover) {
    color: black;
    border-color: #ced4da;
}


.customHeaders {
    background-color: #025BB3!important;
    color: white;
    font-weight: bold;
    text-align: start;
    border-radius: 0!important;
    border: none;
}

.customHeaders a{
    color: white;
    font-size: large;
    font-style: italic;
}

.customHeaders p{
    color: white;
    font-size: large;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
}

.header__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 15vh;
}

.matrix__cells {
    width: 100px!important;
    max-width: unset;
}
.matrix__labelCell {
    background-color: #e9ecef!important;
    border-radius: 40px 40px 40px 40px;
    font-weight: bold;
}

.matrix__labels {
    text-align: start;
    display: flex;
}

.matrix__boxes {
    height: 100%;
    align-items: center;
}
.matrix__percLabel {
    border-left: none;
    background-color: unset;
}
.matrix__prefilled {
    background-color: #e9ecef!important;
}
.matrix__outer {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
}

 ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
 
 .matrix__inner {
    min-width: 1000px;
    display: flex;
    flex-direction: column;
}

.matrixLine:hover {
    background-color: #e5f1fa;
}

.radioMatrix input[type='checkbox']{
    border-radius: 50px;
    background-image: unset!important;
}

.dateEnd {
    width: 101px
}

.dropzone {
    flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-style: solid;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.dropzone.disabled {
    cursor: not-allowed;
}

.dropzone:hover:not(.disabled) {
    background-color: #dadada;
    color: white;
}

.boxFile {
    background-color: #dadada;
    width: 150px;
}

.react-datepicker__triangle {
    left: -50px !important;
}

.form-control:disabled {
    background-color: #ffffff; 
    opacity: 1;
}

.faq__text {
    width: 75%;
    margin-top: 20px;
    font-size: large;
}
.faq__dropdown {
    inset: 0px 0px auto auto !important;
}
.faq__container { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.support__buttons {
    width: 200px;
}
.switch__label {
    text-align: start!important;
}
.admin__links {
    text-decoration: none;
    font-weight: bold;
    color: white;
    font-size: large;
}
.admin__mobile-links {
    text-decoration: none;
    color: black;
}
.admin__links:hover{
    color: white;
}
.admin__button {
    border: 1px solid white;
}

.admin__button:hover {
    background-color: black;
    filter: invert()
}

.admin__sidebar {
    min-height: 100vh;
}

.admin__dropdown a {
    color: white;
    text-decoration: none;
    font-weight: bold;
   
}

.loader {
    z-index: 10000;
    height: 100vh;
}

._loading_overlay_overlay {
    z-index: 10000 !important;
}

:root {
    --toastify-z-index: 99999 !important;
}

.disabledOption {
    background-color: darkgray!important;
}

.width_80 {
    width: 80%;
}

.toast__link {
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.toast__link:hover {
    text-decoration: none;
    color: white;
}
.support__outerBox {
    min-height: 100vh;
    padding-bottom: 20vh;
}
.pdf-modal {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    
}
